<template>
  <div class="card WidgetDetail">
    <div class="row">
      <div class="col-lg-4">
        <div class="input-wrap">
          <label>{{ t('title') }}
            <span v-if="isHeaderTitleRequired" class="required-star">*</span>
          </label>
          <input
            class="form-control"
            v-model="state.header_title"
            type="text"
            :disabled="!state.header_visible"
          />
          <div v-if="isHeaderTitleInvalid" class="error-msg">
            {{ t('required.title') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-wrap">
          <label>{{ t('rightTitle') }}
            <span v-if="isHeaderRightTitleRequired" class="required-star">*</span>
          </label>
          <input
            class="form-control"
            v-model="state.header_right_title"
            type="text"
            :disabled="!state.header_right_visible || !state.header_visible"
          />
          <div v-if="isHeaderRightTitleInvalid" class="error-msg">
            {{ t('required.rightTitle') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <TagsInput
          :label="t('headerRightShowcaseCollections')"
          v-model="state.header_right_shopify_collection_id"
          :disabled="!state.header_right_visible || !state.header_visible"
          :items="collections"
          :required="isHeaderRightShopifyCollectionIdRequired"
        />
      </div>
      <div class="col-lg-4">
        <div class="input-wrap" style="margin-top: 0.5em">
          <MySwitch
            v-model="state.header_visible"
            :label="t('headerIsVisible')"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-wrap" style="margin-top: 0.5em">
          <MySwitch
            v-model="state.header_right_visible"
            :label="t('rightHeaderIsVisible')"
            :disabled="!state.header_visible"
          />
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3">
            <div class="input-wrap">
              <label for="width">{{ t('width') }}
                <span v-if="isWidthRequired" class="required-star">*</span>
              </label>
              <input
                v-model="state.width"
                class="form-control"
                type="number"
                name="width"
                id="width"
              />
              <div v-if="isWidthInvalid" class="error-msg">
                {{ t('required.width') }}
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="input-wrap">
              <label for="height">{{ t('height') }}
                <span v-if="isHeightRequired" class="required-star">*</span>
              </label>
              <input
                v-model="state.height"
                class="form-control"
                type="number"
                name="height"
                id="height"
              />
              <div v-if="isHeightInvalid" class="error-msg">
                {{ t('required.height') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mt-3">
          <div class="input-wrap">
            <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
          </div>
        </div>
        <div class="col-lg-6 mt-3">
          <div class="input-wrap">
            <a
              :disabled="isFormInvalid"
              @click="onSubmit"
              class="btn btn-success"
            >{{ t('create.create') }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import MySwitch from '../../MySwitch'
import TagsInput from '../../TagsInput'
import useGlobal from '../../../composables/useGlobal'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'

import AddHorizontalRequest from '../../../service/RestApi/ShowcaseRequest/Add/AddHorizontalRequest'

export default {
  components: { TagsInput, MySwitch },
  props: {
    collections: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { restApi, t, successNotify, errorNotify, router, emitter } =
      useGlobal()

    const state = reactive({
      header_title: '',
      header_right_title: '',
      header_right_shopify_collection_id: '',
      header_visible: true,
      header_right_visible: true,
      header_right_type: 'SHOPIFY_COLLECTION',
      width: 380,
      height: 200,
    })

    const onSubmit = () => {
      const addHorizontalRequest = new AddHorizontalRequest(
        'HORIZONTAL',
        state.header_title,
        state.header_visible,
        state.header_right_title,
        state.header_right_visible,
        state.header_right_shopify_collection_id,
      ).setBody(parseFloat((state.width / state.height).toFixed(2))).toParams()

      restApi.showcaseAdd(addHorizontalRequest).then((response) => {

        successNotify(t('showcaseCreated'), t('showcaseCreatedDescription'))

        emitter.emit('showcase-item-added', response.getId())

      }).catch((error) => {

        errorNotify(t('showcaseNotCreated'), t('showcaseNotCreatedDescription') + error)

      })
    }

    const cancel = () => {
      router.replace({ name: 'HomePage.Allshowcases.Page' })
    }

    // Required checkers
    const isHeaderTitleRequired = computed(() => {
      return ! $v.value.header_title[0].$response
    })

    const isHeaderRightTitleRequired = computed(() => {
      return ! $v.value.header_right_title[0].$response
    })

    const isHeaderRightShopifyCollectionIdRequired = computed(() => {
      return ! $v.value.header_right_shopify_collection_id[0].$response
    })

    const isWidthRequired = computed(() => {
      return ! $v.value.width[0].$response
    })

    const isHeightRequired = computed(() => {
      return ! $v.value.height[0].$response
    })

    // Invalid chekers
    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.header_title.$error
    })

    const isHeaderRightTitleInvalid = computed(() => {
      return $v.value.header_right_title.$error
    })

    const isHeaderRightShopifyCollectionIdInvalid = computed(() => {
      return $v.value.header_right_shopify_collection_id.$error
    })

    const isWidthInvalid = computed(() => {
      return $v.value.width.$error
    })

    const isHeightInvalid = computed(() => {
      return $v.value.height.$error
    })

    const rules = {
      header_title: [requiredIf(() => state.header_visible)],
      header_right_title: [
        requiredIf(() => state.header_right_visible && state.header_visible),
      ],
      header_right_shopify_collection_id: [
        requiredIf(() => state.header_right_visible && state.header_visible),
      ],
      width: [required],
      height: [required],
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    return {
      t,

      cancel,
      onSubmit,

      state,

      isFormInvalid,
      isHeaderTitleInvalid,
      isHeaderRightTitleInvalid,
      isHeaderRightShopifyCollectionIdInvalid,
      isWidthInvalid,
      isHeightInvalid,

      isHeaderTitleRequired,
      isHeaderRightTitleRequired,
      isHeaderRightShopifyCollectionIdRequired,
      isWidthRequired,
      isHeightRequired,
    }
  },
}
</script>
