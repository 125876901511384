const AddShowcaseRequest = require('../../AddShowcaseRequest')

class AddHorizontalRequest extends AddShowcaseRequest {

  /**
   *
   * @param ratio
   * @return {AddHorizontalRequest}
   */
  setBody(ratio) {
    this.ratio = parseFloat(ratio)
    return this
  }

  /**
   *
   * @return {object}
   */
  toParams() {
    return this.toParamsForAdd({ratio: this.ratio})
  }
}

module.exports = AddHorizontalRequest;
